import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

interface ISuggestion{
  email:string;
  id:string;
}
@Component({
  selector: 'app-client-email-input',
  templateUrl: './client-email-input.component.html',
  styleUrls: ['./client-email-input.component.scss'],
})
export class ClientEmailInputComponent implements OnInit {
  @Output() resultSelected:EventEmitter<ISuggestion>= new EventEmitter();
  results:Array<ISuggestion> =  [];
  loading:boolean = false;
  searchValue:string;
  constructor(private api: ApiService) {
  this.results = new Array(10).fill(    {email:'werwerw@example.com',id:'s'});}

  async updateSearchList( el:any){
    this.results = [];
    this.loading = true;
    this.searchValue = el.value;
    if(!el.value){
      this.results = [];
      this.loading=false;
    }
    const results = await this.api.getEmailMatch(this.searchValue);
    this.results = results;
    this.loading = false;
  }
  emitSelected(r:any){
    this.resultSelected.emit(r);
    this.searchValue=null;
  }
  ngOnInit() {

  }
}
