import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
function decamelize(str, separator){
	separator = typeof separator === 'undefined' ? '_' : separator;

	return str
        .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
        .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
        .toLowerCase();
}

@Component({
  selector: 'app-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss'],
})
export class FormErrorsComponent implements OnInit {
  errors:any[]=[];
  @Input()
  form:any;
  visible:boolean = false;
  timeout: any;
  get groupedErrors(){
    return this.errors.reduce( (groups:any,error:any)=>{
      if(!groups[error.controlName]){
        groups[error.controlName] = [];
      }
      groups[error.controlName].push( {
        errorName: error.errorName,
        errorValue: error.errorValue
      });
      return groups;
    },{})
  }
  constructor() { }
  formatName(n:string){
    if(!n) return;
    n =  decamelize(n,' ');
    n = n.split('_').join(' ');
    return n.toLowerCase();
  }
  ngOnInit() {}
  _calculateErrors(form: FormGroup | FormArray) {
   Object.keys(form.controls).forEach(field => {
     const control = form.get(field);
     if (control instanceof FormGroup || control instanceof FormArray) {
       this.errors = this.errors.concat(this._calculateErrors(control));
       return;
     }

     const controlErrors: ValidationErrors = control.errors;
     if (controlErrors !== null) {
       Object.keys(controlErrors).forEach(keyError => {
         this.errors.push({
           controlName: field,
           errorName: keyError,
           errorValue: controlErrors[keyError]
         });
       });
     }
   });

   // This removes duplicates
   this.errors = this.errors.filter((error, index, self) => self.findIndex(t => {
     return t.controlName === error.controlName && t.errorName === error.errorName;
   }) === index).map( ( error:any )=>{
     if(error.errorValue === 'boolean'){
       const {errorValue,errorName} = error;
       error.errorValue = {};
       let value = {};
       value[errorName]=errorValue;
       error.errorValue=value;
       error.errorName = '';
     }else if(error.errorValue === null){
       error.errorValue = "missing";
     }
     return error;
   });

   return this.errors;
 }
 show(){
   this._calculateErrors( this.form );
   this.visible = true;
   clearTimeout(this.timeout);
   this.timeout = setTimeout( ()=>{
     this.hide();
   }, 5000)
 }
 hide(){
   this.visible = false;
 }


}
