import { Component, OnInit, Input, SimpleChanges, Pipe, Output } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as Mustache from 'mustache';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

let TEMPLATE:string;
@Component({
  selector: 'app-bill-preview',
  templateUrl: './bill-preview.component.html',
  styleUrls: ['./bill-preview.component.scss'],
})
export class BillPreviewComponent implements OnInit {
  @Input()
  preview:any;
  @Input()
  refreshing:boolean=false;


  html:SafeHtml;
  @Output()
  rawHtml:string;
  rawText: string;
  constructor( private http:HttpClient,private sanitizer:DomSanitizer) {

  }

  async loadTemplate(){
    if(TEMPLATE) return TEMPLATE;
    TEMPLATE = await this.http.get('/assets/templates/bill-preview.mustache', {responseType: 'text'}).toPromise();
    return TEMPLATE;
  }
  async render( ){
    const template = await this.loadTemplate();
    // const protocol = window.location.protocol;
    // const port = window.location.port =='443' || window.location.port == '80' ? '' : `:${window.location.port}`;
    // const domain = window.location.hostname;
    //
    // const logo = `${protocol}//${domain}${port}/assets/pos-logo.jpg`;
    const logo = "https://apps.elementdebase.com/assets/pos-logo.jpg";
    const data = {logo, ...this.preview};
    console.log('preview data',data);
    let date = data.date||new Date();
    data.date = new Date(date).toISOString().split('T')[0];
    const rendered =  Mustache.render( template, data );
    // console.log('rendering',this.preview);
    this.html = this.sanitizer.bypassSecurityTrustHtml(rendered);
    this.rawHtml = this.html['changingThisBreaksApplicationSecurity'];
    this.rawText = this.rawHtml.replace(/(<([^>]+)>)/gi, "");
    // console.log(this.rawHtml)
  }

  ngOnInit() {
    // console.log('preview',this.preview)

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('billpreviewchanges',changes)
    if(changes.preview?.currentValue){
      this.render();
    }
       // You can also use categoryId.previousValue and
       // categoryId.firstChange for comparing old and new values

   }


}
