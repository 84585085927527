import { Component, forwardRef, OnDestroy, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  NG_VALIDATORS
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

export interface ContactFormValues {
  firstName: string;
  lastName: string;
  companyName?: string;
  phone: string;
  email: string;
};
const FIELD_NAMES =  ['firstName','lastName','companyName','phone','email'];

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ContactFormComponent),
      multi: true
    }
  ]
})
export class ContactFormComponent implements ControlValueAccessor, OnInit,OnDestroy  {
  form: FormGroup;
  subscriptions: Subscription[] = [];

  @Input()
  requiredFields: string[] = [];
  @Input()
  labelPosition: string = 'floating';
  @Input()
  listLines: string = 'full';
  @Output() clientSelected:EventEmitter<any> = new EventEmitter();
  private _statesLoadedForCountry:string;
  get value(): ContactFormValues {
    return this.form.value;
  }

  set value(value: ContactFormValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }
  resultSelected(r:any){
    this.clientSelected.emit(r);
  }
  constructor(
    private api: ApiService,
    private formBuilder: FormBuilder
  ) {

  }



  ngOnInit(){
    const group = FIELD_NAMES.reduce( (g:any,name:string)=>{
      if(this.requiredFields.includes(name)){
        g[name]= ['', Validators.required ];
      }else{
        g[name] = [];
      }
      return g;
    }, {})
    this.form = this.formBuilder.group(group);
    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => { };
  onTouched: any = () => { };
  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  get errors(){
    if(this.form.valid){
      return null;
    }
    return Object.keys(this.form.controls).reduce( (errs:any,key:string)=>{
      if(!this.form.controls[key].valid){
        errs[key]=this.form.controls[key].errors;
      }
      return errs;
    },{})
  }
  // communicate the inner form validation to the parent form
  validate(_: FormControl) {
    return this.form.valid ? null :  Object.keys(this.form.controls).reduce( (errs:any,key:string)=>{
        if(!this.form.controls[key].valid){
          errs[key]=this.form.controls[key].errors
        }
        return errs;
      },{})
  }

}
