import { Component, OnInit, Input } from '@angular/core';
import { iGenericListHeader } from '../generic-list/generic-list.component';

@Component({
  selector: 'app-generic-list-header',
  templateUrl: './generic-list-header.component.html',
  styleUrls: ['./generic-list-header.component.scss'],
})
export class GenericListHeaderComponent implements OnInit {
  @Input() headers:iGenericListHeader[];
  @Input() alt:any;
  constructor() { }

  ngOnInit() {}

}
