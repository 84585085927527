import { Component, forwardRef, OnDestroy, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  NG_VALIDATORS
} from '@angular/forms';
import { Subscription } from 'rxjs';

export interface PromoFormValues {
  $as: "AMOUNT"|"PERCENT";
  name: string;
  value: number;
  applyTo: "ITEMS"|"ORDER";
};
const FIELD_NAMES =  ['$as','name','value','applyTo'];

@Component({
  selector: 'app-promo-form',
  templateUrl: './promo-form.component.html',
  styleUrls: ['./promo-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PromoFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PromoFormComponent),
      multi: true
    }
  ]
})
export class PromoFormComponent implements ControlValueAccessor, OnInit,OnDestroy  {
  form: FormGroup;
  subscriptions: Subscription[] = [];

  @Input()
  requiredFields: string[] = [];
  @Input()
  labelPosition: string = 'floating';
  @Input()
  listLines: string = 'full';

  get value(): PromoFormValues {
    return this.form.value;
  }

  set value(value: PromoFormValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  constructor(private formBuilder: FormBuilder) {

  }
  computeValuePrefixClass($as:string){
    return $as == 'AMOUNT' ? 'amount' : 'percent';
  }
computeValueMax($as:string){
  return $as == 'AMOUNT' ? Infinity : 100;
}


  ngOnInit(){
    const group = FIELD_NAMES.reduce( (g:any,name:string)=>{
      if(this.requiredFields.includes(name)){
        g[name]= ['', Validators.required ];
      }else{
        g[name] = [];
      }
      return g;
    }, {});

    this.form = this.formBuilder.group(group);
    window.requestAnimationFrame( ()=>{
      if(!this.form.value.$as){

        this.form.get('$as').setValue('AMOUNT');
        this.form.get('applyTo').setValue('ORDER');
      }

    });
    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {


        this.onChange(value);
        this.onTouched();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => { };
  onTouched: any = () => { };
  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    console.log('writeValue,promo',value)
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: FormControl) {
    let errors:any =  this.form.valid ? null :  Object.keys(this.form.controls).reduce( (errs:any,key:string)=>{
        if(!this.form.controls[key].valid){
          errs[key]=this.form.controls[key].errors
        }
        return errs;
      },{})
    let as = this.form.controls['$as'].value;
    let max = this.computeValueMax(as);
    let v = this.form.controls['value'].value;
    if(v > max){
      errors=errors === null ? {} : errors;
      errors['value']='Percentage cannot be greater than 100';
    }
    console.log('errors',errors);
    return errors;
  }

}
