import { Component, OnInit, forwardRef, OnDestroy, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  NG_VALIDATORS
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';


export interface TemplateFormValues {
  for_status: string;
  language: string;
  raw: string;
};
const FIELD_NAMES = ['for_status', 'language', 'raw'];


@Component({
  selector: 'app-template-form',
  templateUrl: './template-form.component.html',
  styleUrls: ['./template-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TemplateFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TemplateFormComponent),
      multi: true
    }
  ]
})
export class TemplateFormComponent implements  ControlValueAccessor, OnInit, OnDestroy {
  explanation:string = `
  {CUSTOMERNAME} will be replaced with name of actual customer.
  {TRACKINGCODE} will be replaced with customer's tracking code.`
  form: FormGroup;
  subscriptions: Subscription[] = [];
  statusOptions:string[] = [];
  @Input()
  template: any;

  @Input()
  requiredFields: string[] = ['for_status','language','raw'];
  @Input()
  labelPosition: string = 'floating';

  @Input()
  listLines: string = 'full';

  @Output()
  onSave: EventEmitter<any> = new EventEmitter();


  get value(): TemplateFormValues {
    return this.form.value;
  }

  set value(value: TemplateFormValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  editorConfig:AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: 'auto',
    minHeight: '100px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '100%',
    translate: 'no',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Compose...',
    defaultParagraphSeparator: '',
    defaultFontName: 'sans-serif',
    defaultFontSize: '3',
    fonts: [
      {class: 'sans-serif', name: 'Sans-serif'},
      {class: 'serif', name: 'Serif'},
      {class: 'monospace', name: 'Monospace'}
    ],


  sanitize: true,
  toolbarPosition: 'top',
  toolbarHiddenButtons: [
    ['undo',
    'redo',
    'indent',
    'outdent',
      'subscript',
      'superscript'],
      [

   'customClasses',
   'insertImage',
   'insertVideo',
   'insertHorizontalRule',
   'removeFormat',
   'toggleEditorMode'
 ]
  ]
  }

  constructor(private formBuilder: FormBuilder, private api: ApiService) {

  }

  changeState(){
    console.log('changeState')
  }
  async loadOptions() {
    let options = await this.api.loadContainerOptions();
    this.statusOptions = Object.values(options.statusMap);
    // let ctrl = this.form.controls['for_status'];
    // if(!ctrl.value){
    //   ctrl.setValue( this.statusOptions[0])
    // }
    // let lang = this.form.controls['language'];
    // if(!lang.value){
    //   lang.setValue('EN');
    // }

    // let states:any;
    // if (this._statesLoadedForCountry == countryCode) {
    //   return;
    // }
    // if (countryCode === null) {
    //   this.states = [];
    //   this._statesLoadedForCountry = null;
    //   this.hasSelectedCountry = false;
    //   this.form.controls['state'].disable();
    // } else {
    //   if(!memo.has(countryCode)){
    //     states = await this.api.getStatesForCountryCode(countryCode);
    //     memo.set(countryCode,states)
    //   }else{
    //     states = memo.get(countryCode);
    //   }
    //   this.states = states;
    //   this._statesLoadedForCountry = countryCode;
    //   if(this.form.controls['state'].value){
    //     const found = this.states.find( (s:any)=>{
    //       return s.isoCode == this.form.controls['state'].value;
    //     });
    //     this.form.controls['state'].reset();
    //     if(found){
    //       this.form.controls['state'].setValue(found.isoCode);
    //     }
    //   }
    //
    //   // this.form.controls['state'].setValue(null);
    //
    //   this.hasSelectedCountry = true;
    // }
      // console.log('this.',this.form.controls['state'].value)
  }

  ngOnInit() {
    const group = FIELD_NAMES.reduce((g: any, name: string) => {
      if (this.requiredFields.includes(name)) {
        g[name] = ['', Validators.required];
      } else {
        g[name] = [];
      }
      return g;
    }, {})
    this.form = this.formBuilder.group(group);
    this.loadOptions();
    // window.requestAnimationFrame(() => {
    //
    //   if (this.form.controls['country'].value === null) {
    //     this.form.get('state').disable();
    //   }
    //
    // });
    //
    // this.subscriptions.push(
    //   this.form.valueChanges.subscribe(value => {
    //
    //     this.onChange(value);
    //     this.onTouched();
    //   })
    //
    // );
  }
  save(){
    console.log(this.form.value);
    this.onSave.emit( {data:{template:this.form.value}} );
  }

  ngAfterViewInit() {
    // this.textarea.ionChange.subscribe(()=>{
    //   this.textarea.autoGrow = true;
    // });
  }

  ngOnChanges(changes: any) {
    console.log('hasTemplate', this.template)
    if(this.template){
      this.form.controls['for_status'].setValue(this.template.for_status);
      this.form.controls['language'].setValue(this.template.language);
      this.form.controls['raw'].setValue(this.template.raw);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => { };
  onTouched: any = () => { };
  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {

    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: FormControl) {
    return this.form.valid ? null : Object.keys(this.form.controls).reduce((errs: any, key: string) => {
      if (!this.form.controls[key].valid) {
        errs[key] = this.form.controls[key].errors
      }
      return errs;
    }, {})
  }


}
