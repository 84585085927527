import { Component, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [
   {
     provide: NG_VALUE_ACCESSOR,
     multi:true,
     useExisting: PhoneInputComponent
   }
 ]
})
export class PhoneInputComponent implements ControlValueAccessor {
  public parts:string[] = [];
  private full:string;
  phone:string;
  onChange = (quantity) => {};

  onTouched = () => {};
  touched = false;

  disabled = false;

  constructor() { }
  writeValue(phone: string): void {
    if(phone){
      console.log('input phone', phone);
      let parts = phone.split('-');
      this.parts = parts;
      this.phone = phone;
    }

  }
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }



   setDisabledState(disabled: boolean) {
     this.disabled = disabled;
   }

  partChanged(pos,value,expectedLength){
    if(String(value).length < expectedLength){
      return;
    }else if(String(value) && String(value).length == expectedLength){
      this.parts[pos-1]=String(value);
    }
    if(this.parts[0] && this.parts[1] && this.parts[2]){

      this.markAsTouched();
      if (!this.disabled) {
        this.full= this.parts.join('-');
        this.onChange(this.full);
      }
    }
  }
  keyUp(value,expectedLength,next,el){
    if(String(value).length == expectedLength){
      if(next === null){
          el.parentElement.parentElement.parentElement.nextElementSibling.querySelector('ion-input').setFocus()
      }else{
        next.focus()
      }

    }
  }
  markAsTouched() {
   if (!this.touched) {
     this.onTouched();
     this.touched = true;
   }
 }


}
