import { Component, OnInit, Output, Input, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { FormErrorsComponent } from '../form-errors/form-errors.component';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';



function pluckProps(o: any, props: string[]) {
  return props.reduce((plucko: any, prop: string) => {
    plucko[prop] = o[prop] || null;
    return plucko;
  }, {})
}
function unPrefixKeys(prefix: string, o: any) {
  return Object.keys(o).reduce((unpfx: any, key: string) => {
    unpfx[key.replace(prefix, '')] = o[key];
    return unpfx;
  }, {})
}

@Component({
  selector: 'app-bill-form',
  templateUrl: './bill-form.component.html',
  styleUrls: ['./bill-form.component.scss'],
})
export class BillFormComponent implements OnInit, OnDestroy {
  @Input()
  type: string = "bill";

  @Output()
  onSave: EventEmitter<any> = new EventEmitter();
  @Output()
  onPreview: EventEmitter<any> = new EventEmitter();

  @Output() clientSelected:EventEmitter<any> = new EventEmitter();

  @Input()
  bill: any;

  @ViewChild('textarea') textarea:any;
  @ViewChild('searchbar') searchbar:any;

  form: FormGroup;
  preview: any;

  errors: any[] = [];
  searchResults: any[] =[]
  handler: any;

  get isQuote() {
    return (this.type) && this.type.toUpperCase() == 'QUOTE';
  }
  constructor(private formBuilder: FormBuilder, private api:ApiService, private authService:AuthService) {
    this.form = this.formBuilder.group({
      contact: [],
      billing: [],
      shipping: [],
      note: [],
      items: this.formBuilder.array([]),
      fees: this.formBuilder.array([]),
      promos: this.formBuilder.array([]),
      payments: this.formBuilder.array([]),
    });
    this.form.valueChanges.pipe(debounceTime(500)).subscribe((e) => {
      this.emitPreview();
    })
    this.emitPreview();
    this.authService.userProfile$.subscribe( (user )=>{
      if(user){
        this.handler = user.name||user.nickname||user.email;
        console.log('HANDLER',this.handler);
      }
    })
    // this.preview = this.form.value;
    // this.addItem();
    // this.addFee();
    // this.addPromo();
    // this.addPayment();

    // console.log(this.form)
  }

  emitPreview(){
    const data = this.prepareData(this.form);
    this.onPreview.emit( data );
  }
  forwardClientSelected(event:any){
    this.clientSelected.emit(event);
  }
  removeItem(array: string, i: any) {
    const formArray = this.form.get(array) as FormArray;
    formArray.removeAt(i);
  }
  addItem(value?: any) {
    const formArray: FormArray = this.form.get('items') as FormArray;
    formArray.push(this.formBuilder.group({
      item: [value]
    }))
  }
  addFee(value?: any) {
    const formArray: FormArray = this.form.get('fees') as FormArray;
    formArray.push(this.formBuilder.group({
      fee: [value]
    }))
  }
  addPromo(value?: any) {
    const formArray: FormArray = this.form.get('promos') as FormArray;
    formArray.push(this.formBuilder.group({
      promo: [value]
    }))
  }
  addPayment(value?: any) {
    const formArray: FormArray = this.form.get('payments') as FormArray;
    formArray.push(this.formBuilder.group({
      payment: [value]
    }))
  }
  _removeNullKeys( o:any ){
    if(!o) return o;
    return Object.keys(o).reduce( (c:any,k:string)=>{
      if(o[k] !== null){
        c[k]=o[k];
      }
      return c;
    }, {} )
  }
  _prefixKeys( prefix:string, o:any){
    return Object.keys(o).reduce( (c:any,k:string)=>{
      c[`${prefix}_${k}`]=o[k];
      return c;
    }, {} );
  }
  getItemControls(){
    const items = this.form.controls.items as FormArray;
    return items.controls
  }
  getFeesControls(){
    const fees = this.form.controls.fees as FormArray;
    return fees.controls
  }
  getPromosControls(){
    const promos = this.form.controls.promos as FormArray;
    return promos.controls
  }
  getPaymentsControls(){
    const payments = this.form.controls.payments as FormArray;
    return payments.controls
  }
  prepareData(event){
    const bill:any = {};
    const client:any = {};
    const formValue = event.value;
    console.log({formValue})

    Object.assign(client, this._removeNullKeys(formValue.contact));
    bill.$as = this.type.toUpperCase();
    if(this.bill){
      bill._id = this.bill._id;
      bill.number = this.bill.number;
    }
    bill.handler = this.handler;
    if(formValue.note){
      bill.when = formValue.note;
    }
    if(formValue.billing){
      let valueObject = this._removeNullKeys(formValue.billing);
      Object.assign(client, this._prefixKeys('billing',valueObject) );
    }
    if(formValue.shipping){
      let valueObject = this._removeNullKeys(formValue.shipping);
      Object.assign(client, this._prefixKeys('shipping',valueObject) );
    }



    if(formValue.fees.length > 0){
      bill.fees = [];
      formValue.fees.forEach( (fee:any)=>{
        bill.fees.push( fee.fee );
      })
    }
    if(formValue.items.length > 0){
      bill.items = [];
      formValue.items.forEach( (item:any)=>{
        bill.items.push( item.item );
      })
    }
    if(formValue.payments.length > 0){
      bill.payments = [];
      formValue.payments.forEach( (item:any)=>{
        bill.payments.push( item.payment );
      })
    }
    if(formValue.promos.length > 0){
      bill.promos = [];
      formValue.promos.forEach( (promo:any)=>{
        bill.promos.push( promo.promo );
      })
    }
    formValue.handler = bill.handler;
    const data = {bill:bill,type:this.type,client:client};
    console.log('data',data)
    return data
  }
  reset(){
    this.form.reset();
  }

  submit(formErrors: FormErrorsComponent) {
    // console.log(this.form.get('items'))
    // console.log(this.form.value)
    if (!this.form.valid) {
      return formErrors.show();
    } else {
      this.onSave.emit({ value: this.form.value, data: this.prepareData(this.form) });
    }

  }
  ngOnInit() { }
  ngOnDestroy() {
    this.bill = undefined;
  }
  async search( query ){
    if(!query) return this.searchResults = [];
    if(query.length < 3) return;
    let result = await this.api.searchClients( query );
    this.searchResults = result.value||[];
  }
  populateClient( client ){
    let contact = pluckProps(client || {}, ['firstName', 'lastName', 'companyName', 'phone', 'email']);
    this.form.get('contact').setValue(contact);
    let shipping = pluckProps(client || {}, ['shipping_address', 'shipping_address_extra', 'shipping_country', 'shipping_city', 'shipping_state', 'shipping_postal_code', 'shipping_note']);
    shipping = unPrefixKeys('shipping_', shipping);
    this.form.get('shipping').setValue(shipping)
    let billing = pluckProps(client || {}, ['billing_address', 'billing_address_extra', 'billing_country', 'billing_city', 'billing_state', 'billing_postal_code', 'billing_note']);
    billing = unPrefixKeys('billing_', billing);
    this.form.get('billing').setValue(billing)
    this.searchbar.value=null;
    this.searchResults=[];
  }

  populateContact() {
    let contact = pluckProps(this.bill.client || {}, ['firstName', 'lastName', 'companyName', 'phone', 'email']);
    this.form.get('contact').setValue(contact);
  }
  populateShipping() {
    let shipping = pluckProps(this.bill.client || {}, ['shipping_address', 'shipping_address_extra', 'shipping_country', 'shipping_city', 'shipping_state', 'shipping_postal_code', 'shipping_note']);
    shipping = unPrefixKeys('shipping_', shipping);
    this.form.get('shipping').setValue(shipping)
  }
  populateBilling() {
    let billing = pluckProps(this.bill.client || {}, ['billing_address', 'billing_address_extra', 'billing_country', 'billing_city', 'billing_state', 'billing_postal_code', 'billing_note']);
    billing = unPrefixKeys('billing_', billing);
    this.form.get('billing').setValue(billing)
  }
  populateItems() {
    if(this.bill.items){
      this.bill.items.forEach( ( item:any )=>{
        this.addItem( item)
      })
    }
  }
  populateFees() {
    if(this.bill.fees){
      this.bill.fees.forEach( ( fee:any )=>{
        this.addFee( fee)
      })
    }
  }
  populatePromos() {
    if(this.bill.promos){
      this.bill.promos.forEach( ( promo:any )=>{
        this.addPromo( promo)
      })
    }
  }
  populatePayments() {
    console.log('populatePayments',this.bill.payments)
    if(this.bill.payments){
      this.bill.payments.forEach( ( pmnt:any )=>{
        this.addPayment( pmnt)
      })
    }
   }
textChange(ta:any){
  console.log('textChange',ta);
}
setAutoGrow(){
  this.textarea.autoGrow = true;

}
  ngOnChanges(changes: any) {
    console.log('hasbill', this.bill)
    if (this.bill) {
      this.populateContact();
      this.populateShipping();
      this.populateBilling();
      this.populateItems();
      this.populateFees();
      this.populatePromos();
      this.populatePayments();

      if(this.bill.when){


        this.form.get('note').setValue(this.bill.when);

        window.requestAnimationFrame(()=>{
            this.textarea.rows = this.bill.when.split('\n').length + 2;
        })
      }

    }



    console.log(this.bill)
  }

}
