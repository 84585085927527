import { Injectable } from '@angular/core';
//@ts-ignore
const API_HOST:any = window.EDB_API_HOST;
function makeid(rnd:number) {
  var length = Math.floor(Math.random()*rnd);
   var result           = '';
   var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   var charactersLength = characters.length;
   for ( var i = 0; i < length; i++ ) {
      result = result + characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   console.log(result)
   return result.slice(0,length);
}
const StatesMap: Map<string, any> = new Map<string, any>();



@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor() {


  }


  async getClient(id:string){
    const response = await fetch(`${API_HOST}/clients/${id}`);
    const data = await response.json();
    return data
  }
  async getEmailMatch(partialEmail:string){
    const response = await fetch(`${API_HOST}/clients/match-email/${partialEmail}`);
    const data = await response.json();
    console.log(data)
    return Array.isArray(data.value) ? data.value : [];
  }
  async getStatesForCountryCode(countryCode: string) {
    if (!StatesMap.has(countryCode)) {
      const response = await fetch(`${API_HOST}/meta/states/${countryCode}`);
      const data = await response.json();
      StatesMap.set(countryCode, data);
    }
    return StatesMap.get(countryCode);
  }

  async getQuotes(page:number=0,limit:number){
    const response = await fetch(`${API_HOST}/quotes/page/${page}/${limit}`);
    const data = await response.json();
    // console.log(data)
    return data;
    // return data;
  }


  async getClients(page:number=0,limit:number){
    const response = await fetch(`${API_HOST}/clients/page/${page}/${limit}`);
    const data = await response.json();
    // console.log(data)
    data.pages = data.pages||{
      page:0,next:null,prev:null,total:0,pages:[0]
    }
    return data;
    // return data;
  }

  async getContainers(page:number=0,limit:number){

    const response = await fetch(`${API_HOST}/containers/page/${page}/${limit}`);
    const data = await response.json();
    return data;

  }


  async getContainer(id:string){

    const response = await fetch(`${API_HOST}/containers/${id}`);
    const data = await response.json();
    return data;

  }

  async getTemplates(page:number=0,limit:number){
    const response = await fetch(`${API_HOST}/templates/page/${page}/${limit}`);
    const data = await response.json();
    return data;

  }

  async getShipments(page:number=0,limit:number){
    const response = await fetch(`${API_HOST}/shipments/page/${page}/${limit}`);
    const data = await response.json();
    return data;

  }

  async loadContainerOptions(){
    const response = await fetch(`${API_HOST}/meta/container-options`);
    const data = await response.json();
    return data;
  }

  async loadShipmentOptions(){
    const response1 = await fetch(`${API_HOST}/containers`);
    const response2 = await fetch(`${API_HOST}/clients`);
    const containers = await response1.json();
    const clients = await response2.json();
    return {containers:containers.value,clients:clients.value};
  }

  async getBills(page:number,limit:number){
    const response = await fetch(`${API_HOST}/bills/page/${page}/${limit}`);
    const data = await response.json();
    return data;
  }

  async searchBills(query){
    const response = await fetch(`${API_HOST}/bills/search/${query}`);
    const data = await response.json();
    return data;
  }
  async searchQuotes(query){
    const response = await fetch(`${API_HOST}/quotes/search/${query}`);
    const data = await response.json();
    return data;
  }
  async searchShipments(query){
    const response = await fetch(`${API_HOST}/shipments/search/${query}`);
    const data = await response.json();
    return data;
  }
  async searchContainers(query){
    const response = await fetch(`${API_HOST}/containers/search/${query}`);
    const data = await response.json();
    return data;
  }
  async searchClients(query){
    const response = await fetch(`${API_HOST}/clients/search/${query}`);
    const data = await response.json();
    return data;
  }


  async getQuote(id:string){
    const response = await fetch(`${API_HOST}/quotes/${id}`);
    const data = await response.json();
    return data;
  }

  async getTemplate(id:string){
    const response = await fetch(`${API_HOST}/templates/${id}`);
    const data = await response.json();
    return data;
  }

  async getShipment(id:string){
    const response = await fetch(`${API_HOST}/shipments/${id}`);
    const data = await response.json();
    return data;
  }

  async getBill(id:string){
    const response = await fetch(`${API_HOST}/bills/${id}`);
    const data = await response.json();
    return data;
  }

  async upgradeQuote(id:string){
    const quote = await this.getQuote(id);
    quote.value.$as = 'BILL';
    const client = await this.getClient(quote.value.$client);
    const upgrade = await this.updateQuote(client.value,quote.value);
    return true;
  }

  async sendMail(data:any){
    console.log('sendMail',data);
    const body = JSON.stringify( { email:data });
    const response = await fetch(`${API_HOST}/email`, {
      method: "post",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body:body
    });
    const result = await response.json();
    console.log(result)
    return result;
  }

  async deleteQuote(id:string){
    const response = await fetch(`${API_HOST}/quotes/${id}`, {
        method: 'DELETE'
    });
    return true;
  }

  async deleteBill(id:string){
    const response = await fetch(`${API_HOST}/bills/${id}`, {
        method: 'DELETE'
    });
    return true;
  }

  async saveQuote(client: any={}, quote: any={}) {
    const body = JSON.stringify( { client, quote });
    console.log(body)
    const response = await fetch(`${API_HOST}/quotes/`, {
      method: "post",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body:body
    });
    const data = await response.json();
    return data;
  }



  async updateQuote(client:any={},quote:any={}){
    const body = JSON.stringify( { quote: Object.assign({},quote, {client: client }) });
    const response = await fetch(`${API_HOST}/quotes/`, {
      method: "put",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body:body
    });
    const data = await response.json();
    return data;
  }

  async saveContainer(container: any={}) {
    const body = JSON.stringify( { container });
    console.log(body)
    const response = await fetch(`${API_HOST}/containers/`, {
      method: "post",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body:body
    });
    const data = await response.json();
    return data;
  }

  async updateContainer(container){
    const body = JSON.stringify( { container });
    console.log('updateContainer',body)
    const response = await fetch(`${API_HOST}/containers/`, {
      method: "put",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body:body
    });
    console.log('put',body)
    const data = await response.json();
    return data;
  }


  async saveShipment(shipment: any={}) {
    const body = JSON.stringify( { shipment });
    console.log(body)
    const response = await fetch(`${API_HOST}/shipments/`, {
      method: "post",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body:body
    });
    const data = await response.json();
    return data;
  }


  async updateShipment(shipment){
    const body = JSON.stringify( { shipment });
    console.log('updateShipment',body)
    const response = await fetch(`${API_HOST}/shipments/`, {
      method: "put",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body:body
    });
    console.log('put',body)
    const data = await response.json();
    return data;
  }

  async saveTemplate(template: any={}) {
    const body = JSON.stringify( { template });
    console.log(body)
    const response = await fetch(`${API_HOST}/templates/`, {
      method: "post",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body:body
    });
    const data = await response.json();
    return data;
  }

  async updateTemplate(template){
    const body = JSON.stringify( { template });
    console.log('updateTemplate',body)
    const response = await fetch(`${API_HOST}/templates/`, {
      method: "put",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body:body
    });
    const data = await response.json();
    return data;
  }

  async saveBill(client: any={}, bill: any={}) {
    const body = JSON.stringify( { client, bill });
    const response = await fetch(`${API_HOST}/bills/`, {
      method: "post",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body:body
    });
    const data = await response.json();
    return data;
  }

  async updateBill(client:any={},bill:any={}){
    const body = JSON.stringify( { bill: Object.assign({},bill, {client: client }) });
    console.warn('!!!!!updateBill',body)
    const response = await fetch(`${API_HOST}/bills/`, {
      method: "put",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body:body
    });
    const data = await response.json();
    return data;
  }


  async saveClient(client: any={}) {
    console.log('Saving client', client );
    const body = JSON.stringify( { client });
    const response = await fetch(`${API_HOST}/clients/`, {
      method: "post",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body:body
    });
    const data = await response.json();
    return data;
  }

  async updateClient(client:any={}){
        console.log('Updating client', client );
    const body = JSON.stringify( {client});
    const response = await fetch(`${API_HOST}/clients/`, {
      method: "put",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body:body
    });
    const data = await response.json();
    console.log('response', data)
    return data;
  }

  async getPreview(client:any,bill:any={}){
    const body = JSON.stringify( { bill: Object.assign({},bill, {client:client}) });
    const response = await fetch(`${API_HOST}/meta/preview/`, {
      method: "post",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body:body
    });
    const data = await response.json();
    return data;

  }

  async sendShipmentEmail(item:any){
    const response = await fetch(`${API_HOST}/email/container-bill/${item._id}`, {
      method: "post",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    const data = await response.json();
    console.log('sendShipmentEmail',{data})
    return data;

  }
  async sendContainerEmail(item:any){
    const response = await fetch(`${API_HOST}/email/container-recipients/${item._id}`, {
      method: "post",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    const data = await response.json();
    console.log('sendContainerEmail',{data})
    return data;

  }
}
