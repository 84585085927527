import { Component, OnInit, Input, Output, EventEmitter, ContentChild, TemplateRef } from '@angular/core';
import { AlertController } from '@ionic/angular';
export interface iGenericListHeader{
  domid: string;
  label: string;
  prop: string|Function;
  pipe?:any;
  cssWidth:string;
  cssMinWidth:string;
}
@Component({
  selector: 'app-generic-list',
  templateUrl: './generic-list.component.html',
  styleUrls: ['./generic-list.component.scss'],
})
export class GenericListComponent implements OnInit {
  @Input() preview:boolean = false;
  @Input() data:any[]=[];
  @Input() headers:iGenericListHeader[]=[];
  @Input() type:string='bill';
  @Input() pages:any;
  @Input() loading:boolean;

  @Output() idClicked = new EventEmitter();
  @Output() nextPageClicked = new EventEmitter();
  @Output() prevPageClicked = new EventEmitter();
  @Output() loadPageClicked = new EventEmitter();



  @ContentChild('item',{static: false}) itemTemplateRef: TemplateRef<any>;

  constructor() { }

  ngOnInit() {}
  proxyIdClick(event){
    this.idClicked.emit( event );
  }
  nextPage(){
    this.nextPageClicked.emit({current: this.pages.page });
    console.log(    'generic list nextPage() emited', {current: this.pages.page })
  }
  prevPage(){
    this.prevPageClicked.emit({current: this.pages.page });
        console.log(    'generic list prevPage() emited', {current: this.pages.page })
  }
  loadPage(number:number){
    this.loadPageClicked.emit({value: number, current:this.pages.page });
  }

}
