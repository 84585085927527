import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "./services/auth.guard";
const routes: Routes = [
  {
    path: 'home',

    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'quote/:action',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/quote-editor/quote-editor.module').then( m => m.QuoteEditorPageModule)
  },
  {
    path: 'bill/:action',
        canActivate: [AuthGuard],
    loadChildren: () => import('./pages/bill-editor/bill-editor.module').then( m => m.BillEditorPageModule)
  },
  {
    path: 'quote/:action/:id',
        canActivate: [AuthGuard],
    loadChildren: () => import('./pages/quote-editor/quote-editor.module').then( m => m.QuoteEditorPageModule)
  },
  {
    path: 'bill/:action/:id',
        canActivate: [AuthGuard],
    loadChildren: () => import('./pages/bill-editor/bill-editor.module').then( m => m.BillEditorPageModule)
  },
  {
    path: 'bills',
        canActivate: [AuthGuard],
    loadChildren: () => import('./pages/bill-list/bill-list.module').then( m => m.BillListPageModule)
  },
  {
    path: 'quotes',
        canActivate: [AuthGuard],
    loadChildren: () => import('./pages/quote-list/quote-list.module').then( m => m.QuoteListPageModule)
  },
  {
    path: 'clients',
            canActivate: [AuthGuard],
    loadChildren: () => import('./pages/client-list/client-list.module').then( m => m.ClientListPageModule)
  },
  {
    path: 'containers',
                canActivate: [AuthGuard],
    loadChildren: () => import('./pages/container-list/container-list.module').then( m => m.ContainerListPageModule)
  },
  {
    path: 'templates',
                canActivate: [AuthGuard],
    loadChildren: () => import('./pages/template-list/template-list.module').then( m => m.TemplateListPageModule)
  },
  {
    path: 'template/:action',
                canActivate: [AuthGuard],
    loadChildren: () => import('./pages/template-editor/template-editor.module').then( m => m.TemplateEditorPageModule)
  },
  {
    path: 'template/:action/:id',
                canActivate: [AuthGuard],
    loadChildren: () => import('./pages/template-editor/template-editor.module').then( m => m.TemplateEditorPageModule)
  },
  {
    path: 'tracking-codes',
                canActivate: [AuthGuard],
    loadChildren: () => import('./pages/tracking-code-list/tracking-code-list.module').then( m => m.TrackingCodeListPageModule)
  },
  {
    path: 'generic',

    loadChildren: () => import('./pages/generic-list/generic-list.module').then( m => m.GenericListPageModule)
  },
  {
    path: 'container/:action',
                canActivate: [AuthGuard],
    loadChildren: () => import('./pages/container-editor/container-editor.module').then( m => m.ContainerEditorPageModule)
  },
  {
    path: 'container/:action/:id',
                canActivate: [AuthGuard],
    loadChildren: () => import('./pages/container-editor/container-editor.module').then( m => m.ContainerEditorPageModule)
  },
  {
    path: 'track',
    loadChildren: () => import('./pages/track/track.module').then( m => m.TrackPageModule)
  },
  {
    path: 'shipments',
    loadChildren: () => import('./pages/shipment-list/shipment-list.module').then( m => m.ShipmentListPageModule)
  },
  {
    path: 'shipment/:action',
    loadChildren: () => import('./pages/shipment-editor/shipment-editor.module').then( m => m.ShipmentEditorPageModule)
  },
  {
    path: 'shipment/:action/:id',
    loadChildren: () => import('./pages/shipment-editor/shipment-editor.module').then( m => m.ShipmentEditorPageModule)
  },
  {
    path: 'client/:action',
    loadChildren: () => import('./pages/client-editor/client-editor.module').then( m => m.ClientEditorPageModule)
  },
  {
    path: 'client/:action/:id',
    loadChildren: () => import('./pages/client-editor/client-editor.module').then( m => m.ClientEditorPageModule)
  },
  {
    path: 'modal-bill-preview',
    loadChildren: () => import('./pages/modal-bill-preview/modal-bill-preview.module').then( m => m.ModalBillPreviewPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
