import { Component, OnInit, forwardRef, OnDestroy, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  NG_VALIDATORS
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
interface ShipmentFormValues{
  number: string;
  $container:string;
  $client:string;
  details:string;
  target:string;
  city:string;
}
const FIELD_NAMES = ['number','$container','$client','details','target','city'];
@Component({
  selector: 'app-shipment-form',
  templateUrl: './shipment-form.component.html',
  styleUrls: ['./shipment-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ShipmentFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ShipmentFormComponent),
      multi: true
    }
  ]
})
export class ShipmentFormComponent implements  ControlValueAccessor, OnInit, OnDestroy   {
  containers: any[];
  clients: any[];
  form: FormGroup;
  subscriptions: Subscription[] = [];

  @Input()
  shipment: any;

  @Input()
  requiredFields: string[] = [...FIELD_NAMES];
  @Input()
  labelPosition: string = 'stacked';

  @Input()
  listLines: string = 'full';

  @Output()
  onSave: EventEmitter<any> = new EventEmitter();


  get value(): ShipmentFormValues {
    return this.form.value;
  }

  set value(value: ShipmentFormValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }


  constructor(private formBuilder: FormBuilder, private api: ApiService) {

  }

  changeState(){
    console.log('changeState')
  }
  async loadOptions() {
    let options = await this.api.loadShipmentOptions();
    window.setTimeout( ()=>{

      this.containers = options.containers;
      this.clients = options.clients;
    },10)



  }

  async ngOnInit() {
    const group = FIELD_NAMES.reduce((g: any, name: string) => {
      if (this.requiredFields.includes(name)) {
        g[name] = ['', Validators.required];
      } else {
        g[name] = [];
      }
      return g;
    }, {})
    this.form = this.formBuilder.group(group);
    await this.loadOptions();
    // window.requestAnimationFrame(() => {
    //
    //   if (this.form.controls['country'].value === null) {
    //     this.form.get('state').disable();
    //   }
    //
    // });
    //
    // this.subscriptions.push(
    //   this.form.valueChanges.subscribe(value => {
    //
    //     this.onChange(value);
    //     this.onTouched();
    //   })
    //
    // );
  }
  save(){
    console.log(this.form.value);

      this.onSave.emit( {data:{shipment:this.form.value}} );

  }

  ngAfterViewInit() {
    // this.textarea.ionChange.subscribe(()=>{
    //   this.textarea.autoGrow = true;
    // });
  }

  async ngOnChanges(changes: any) {
    console.log('hasShipment', this.shipment)

    if(this.shipment){
        if(!this.containers && !this.clients){
          await this.loadOptions();

        }
      console.log('this',this.containers,this.clients)

        FIELD_NAMES.forEach( (name)=>{
          this.form.controls[name].setValue(this.shipment[name]);
        })


    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => { };
  onTouched: any = () => { };
  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {

    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: FormControl) {
    return this.form.valid ? null : Object.keys(this.form.controls).reduce((errs: any, key: string) => {
      if (!this.form.controls[key].valid) {
        errs[key] = this.form.controls[key].errors
      }
      return errs;
    }, {})
  }


}
