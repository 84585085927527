import { Component, forwardRef, OnDestroy, Input, OnInit, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  NG_VALIDATORS
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
const memo = new Map();

export interface AddressFormValues {
  address: string;
  address_extra: string;
  city: string;
  country: string;
  state: string;
  postal_code: string;
  note: string;
};
const FIELD_NAMES = ['address', 'address_extra', 'city', 'country', 'state', 'postal_code', 'note'];

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressFormComponent),
      multi: true
    }
  ]
})
export class AddressFormComponent implements ControlValueAccessor, OnInit, OnDestroy {
  form: FormGroup;
  subscriptions: Subscription[] = [];
  hasSelectedCountry: boolean = false;
  states: any[] = [];
  @Input()
  requiredFields: string[] = [];
  @Input()
  labelPosition: string = 'floating';

  @Input()
  listLines: string = 'full';

  @ViewChild('textarea') textarea:any;

  private _initialState:string;
  private _statesLoadedForCountry: string;
  get value(): AddressFormValues {
    return this.form.value;
  }

  set value(value: AddressFormValues) {
    if (value.country) {
      this.loadStates(value.country);
    } else {
      this.loadStates(null);
    }
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  constructor(private formBuilder: FormBuilder, private api: ApiService) {

  }

changeState(){
  console.log('changeState')
}
  async loadStates(countryCode: string | null) {
    let states:any;
    if (this._statesLoadedForCountry == countryCode) {
      return;
    }
    if (countryCode === null) {
      this.states = [];
      this._statesLoadedForCountry = null;
      this.hasSelectedCountry = false;
      this.form.controls['state'].disable();
    } else {
      if(!memo.has(countryCode)){
        states = await this.api.getStatesForCountryCode(countryCode);
        memo.set(countryCode,states)
      }else{
        states = memo.get(countryCode);
      }
      this.states = states;
      this._statesLoadedForCountry = countryCode;
      if(this.form.controls['state'].value){
        const found = this.states.find( (s:any)=>{
          return s.isoCode == this.form.controls['state'].value;
        });
        this.form.controls['state'].reset();
        if(found){
          this.form.controls['state'].setValue(found.isoCode);
        }
      }

      // this.form.controls['state'].setValue(null);

      this.hasSelectedCountry = true;
    }
      console.log('this.',this.form.controls['state'].value)
  }

  ngOnInit() {
    const group = FIELD_NAMES.reduce((g: any, name: string) => {
      if (this.requiredFields.includes(name)) {
        g[name] = ['', Validators.required];
      } else {
        g[name] = [];
      }
      return g;
    }, {})
    this.form = this.formBuilder.group(group);
    window.requestAnimationFrame(() => {

      if (this.form.controls['country'].value === null) {
        this.form.get('state').disable();
      }

    });

    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        if (value.country) {
          this.loadStates(value.country);
        } else {
          this.loadStates(null);
        }
        this.toggleStateAccess(value.country);
        this.onChange(value);
        this.onTouched();
      })

    );
  }

  ngAfterViewInit() {
    this.textarea.ionChange.subscribe(()=>{
      this.textarea.autoGrow = true;
    });
  }

  toggleStateAccess(country) {
    window.requestAnimationFrame(() => {
      if (country === null) {
        this.form.controls.state.disable({ emitEvent: false });
      } else {
        this.form.controls.state.enable({ emitEvent: false });;
        this.form.controls.state;
      }
    })
  }
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => { };
  onTouched: any = () => { };
  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {

    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: FormControl) {
    return this.form.valid ? null : Object.keys(this.form.controls).reduce((errs: any, key: string) => {
      if (!this.form.controls[key].valid) {
        errs[key] = this.form.controls[key].errors
      }
      return errs;
    }, {})
  }
}
