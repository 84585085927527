import { Component, OnInit, forwardRef, OnDestroy, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  NG_VALIDATORS
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { ContactFormValues } from '../contact-form/contact-form.component';

const FIELD_NAMES = ['firstName','lastName','phone','email'];


@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ClientFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ClientFormComponent),
      multi: true
    }
  ]
})
export class ClientFormComponent implements  ControlValueAccessor, OnInit, OnDestroy   {


  form: FormGroup;
  subscriptions: Subscription[] = [];

  @Input()
  client: any;

  @Input()
  requiredFields: string[] = [...FIELD_NAMES];
  @Input()
  labelPosition: string = 'stacked';

  @Input()
  listLines: string = 'full';

  @Output()
  onSave: EventEmitter<any> = new EventEmitter();


  get value(): ContactFormValues {
    return this.form.value;
  }

  set value(value: ContactFormValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }


  constructor(private formBuilder: FormBuilder, private api: ApiService) {

  }

  changeState(){
    console.log('changeState')
  }

  ngOnInit() {
    const group = FIELD_NAMES.reduce((g: any, name: string) => {
      if (this.requiredFields.includes(name)) {
        g[name] = ['', Validators.required];
      } else {
        g[name] = [];
      }
      return g;
    }, {})
    this.form = this.formBuilder.group(group);

    // window.requestAnimationFrame(() => {
    //
    //   if (this.form.controls['country'].value === null) {
    //     this.form.get('state').disable();
    //   }
    //
    // });
    //
    // this.subscriptions.push(
    //   this.form.valueChanges.subscribe(value => {
    //
    //     this.onChange(value);
    //     this.onTouched();
    //   })
    //
    // );
  }
  save(){
    console.log(this.form.value);
      this.onSave.emit( {data:{client:this.form.value}} );

  }

  ngAfterViewInit() {
    // this.textarea.ionChange.subscribe(()=>{
    //   this.textarea.autoGrow = true;
    // });
  }

  ngOnChanges(changes: any) {
    console.log('hasContianer', this.client)
    if(this.client){
      FIELD_NAMES.forEach( (name)=>{
                this.form.controls[name].setValue(this.client[name]);
      })

    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => { };
  onTouched: any = () => { };
  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {

    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: FormControl) {
    return this.form.valid ? null : Object.keys(this.form.controls).reduce((errs: any, key: string) => {
      if (!this.form.controls[key].valid) {
        errs[key] = this.form.controls[key].errors
      }
      return errs;
    }, {})
  }


}
