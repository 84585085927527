import { Component, OnInit, forwardRef, OnDestroy, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  NG_VALIDATORS
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

interface ContainerFormValues{
  tag: string;
  isActive: boolean;
  eta: string;
  weeks?: number;
  status: string;
  location: string;
}

const FIELD_NAMES = ['tag', 'isActive', 'eta','weeks','status','location'];

@Component({
  selector: 'app-container-form',
  templateUrl: './container-form.component.html',
  styleUrls: ['./container-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContainerFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ContainerFormComponent),
      multi: true
    }
  ]
})
export class ContainerFormComponent implements  ControlValueAccessor, OnInit, OnDestroy  {
  statuses: string[];
  locations: string[];
  form: FormGroup;
  subscriptions: Subscription[] = [];

  @Input()
  container: any;

  @Input()
  requiredFields: string[] = ['tag','isActive','status','location'];
  @Input()
  labelPosition: string = 'stacked';

  @Input()
  listLines: string = 'full';

  @Output()
  onSave: EventEmitter<any> = new EventEmitter();


  get value(): ContainerFormValues {
    return this.form.value;
  }

  set value(value: ContainerFormValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }


  constructor(private formBuilder: FormBuilder, private api: ApiService) {

  }

  changeState(){
    console.log('changeState')
  }
  async loadOptions() {
    let options = await this.api.loadContainerOptions();
    this.statuses = Object.values(options.statusMap);
    this.locations = Object.values(options.locationMap);

    // let ctrl = this.form.controls['for_status'];
    // if(!ctrl.value){
    //   ctrl.setValue( this.statusOptions[0])
    // }
    // let lang = this.form.controls['language'];
    // if(!lang.value){
    //   lang.setValue('EN');
    // }

    // let states:any;
    // if (this._statesLoadedForCountry == countryCode) {
    //   return;
    // }
    // if (countryCode === null) {
    //   this.states = [];
    //   this._statesLoadedForCountry = null;
    //   this.hasSelectedCountry = false;
    //   this.form.controls['state'].disable();
    // } else {
    //   if(!memo.has(countryCode)){
    //     states = await this.api.getStatesForCountryCode(countryCode);
    //     memo.set(countryCode,states)
    //   }else{
    //     states = memo.get(countryCode);
    //   }
    //   this.states = states;
    //   this._statesLoadedForCountry = countryCode;
    //   if(this.form.controls['state'].value){
    //     const found = this.states.find( (s:any)=>{
    //       return s.isoCode == this.form.controls['state'].value;
    //     });
    //     this.form.controls['state'].reset();
    //     if(found){
    //       this.form.controls['state'].setValue(found.isoCode);
    //     }
    //   }
    //
    //   // this.form.controls['state'].setValue(null);
    //
    //   this.hasSelectedCountry = true;
    // }
      // console.log('this.',this.form.controls['state'].value)
  }

  ngOnInit() {
    const group = FIELD_NAMES.reduce((g: any, name: string) => {
      if (this.requiredFields.includes(name)) {
        g[name] = ['', Validators.required];
      } else {
        g[name] = [];
      }
      return g;
    }, {})
    this.form = this.formBuilder.group(group);
    this.loadOptions();
    // window.requestAnimationFrame(() => {
    //
    //   if (this.form.controls['country'].value === null) {
    //     this.form.get('state').disable();
    //   }
    //
    // });
    //
    // this.subscriptions.push(
    //   this.form.valueChanges.subscribe(value => {
    //
    //     this.onChange(value);
    //     this.onTouched();
    //   })
    //
    // );
  }
  save(){
    console.log(this.form.value);
      this.onSave.emit( {data:{container:this.form.value}} );

  }

  ngAfterViewInit() {
    // this.textarea.ionChange.subscribe(()=>{
    //   this.textarea.autoGrow = true;
    // });
  }

  ngOnChanges(changes: any) {
    console.log('hasContianer', this.container)
    if(this.container){
      FIELD_NAMES.forEach( (name)=>{
                this.form.controls[name].setValue(this.container[name]);
      })

    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => { };
  onTouched: any = () => { };
  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {

    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: FormControl) {
    return this.form.valid ? null : Object.keys(this.form.controls).reduce((errs: any, key: string) => {
      if (!this.form.controls[key].valid) {
        errs[key] = this.form.controls[key].errors
      }
      return errs;
    }, {})
  }


}
