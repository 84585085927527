import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { AddressFormComponent } from './forms/address-form/address-form.component';
import { FeeFormComponent } from './forms/fee-form/fee-form.component';
import { ContactFormComponent } from './forms/contact-form/contact-form.component';
import { ItemFormComponent } from './forms/item-form/item-form.component';
import { PaymentFormComponent } from './forms/payment-form/payment-form.component';
import { PromoFormComponent } from './forms/promo-form/promo-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BillFormComponent } from './forms/bill-form/bill-form.component';
import { BillPreviewComponent } from './previews/bill-preview/bill-preview.component';
import { FormErrorsComponent } from './forms/form-errors/form-errors.component';
// import { BillListItemComponent } from './bill-list-item/bill-list-item.component';
// import { BillListHeaderComponent } from './bill-list-header/bill-list-header.component';
// import { BillListComponent } from './bill-list/bill-list.component';
import { ClientEmailInputComponent } from './forms/client-email-input/client-email-input.component';
// import { ClientListComponent } from './client-list/client-list.component';
// import { ClientListHeaderComponent } from './client-list-header/client-list-header.component';
// import { ClientListItemComponent } from './client-list-item/client-list-item.component';
// import { ContainerListComponent } from './container-list/container-list.component';
// import { ContainerListHeaderComponent } from './container-list-header/container-list-header.component';
// import { ContainerListItemComponent } from './container-list-item/container-list-item.component';
import { GenericListComponent } from './generic-list/generic-list.component';
import { GenericListHeaderComponent } from './generic-list-header/generic-list-header.component';
import { GenericListItemComponent } from './generic-list-item/generic-list-item.component';
import { PhoneInputComponent } from './forms/phone-input/phone-input.component';
import { TemplateFormComponent } from './forms/template-form/template-form.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ContainerFormComponent } from './forms/container-form/container-form.component';
import { ShipmentFormComponent } from './forms/shipment-form/shipment-form.component';
import { ClientFormComponent } from './forms/client-form/client-form.component';



const APP_COMPONENTS = [
  AddressFormComponent,
  ContactFormComponent,
  FeeFormComponent,
  ItemFormComponent,
  PaymentFormComponent,
  PromoFormComponent,
  BillFormComponent,
  BillPreviewComponent,
  FormErrorsComponent,
  // BillListItemComponent,
  // BillListHeaderComponent,
  // BillListComponent,
  ClientEmailInputComponent,
  // ClientListComponent,
  // ClientListHeaderComponent,
  // ClientListItemComponent,
  // ContainerListComponent,
  // ContainerListHeaderComponent,
  // ContainerListItemComponent,
  GenericListComponent,
  GenericListHeaderComponent,
  GenericListItemComponent,
  PhoneInputComponent,
TemplateFormComponent,
ContainerFormComponent,
ShipmentFormComponent,
ClientFormComponent
]

@NgModule({
  declarations: APP_COMPONENTS,
  exports: APP_COMPONENTS,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    IonicModule
  ]
})
export class ComponentsModule { }
