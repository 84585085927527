import { Component, OnInit, Input, ContentChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { iGenericListHeader } from '../generic-list/generic-list.component';

interface IListItem{
  id: string;
  value: any;
}

@Component({
  selector: 'app-generic-list-item',
  templateUrl: './generic-list-item.component.html',
  styleUrls: ['./generic-list-item.component.scss'],
})
export class GenericListItemComponent implements OnInit {
  @Input()
  preview:boolean=false;

  @Input()
  item:any;


  @Input()
  headers:iGenericListHeader[];

  @Input()
  alt:string;
  constructor() { }

  @Output() idClicked = new EventEmitter();
  ngOnInit() {}
  ngOnChanges(changes: any): void {
      // console.log(changes)
  }
previewClicked( event, item ){
  event.preventDefault();
  event.stopPropagation();
  this.idClicked.emit( item );
}
renderProperty( item:any, prop:string|Function, pipe?:any){
  let value;
  if(pipe){
    value = pipe( this.renderProperty(item,prop) );
  }else if('function' == typeof prop){
    value = prop(item) || '--';
  }else if( item[prop] ){
    value = item[prop]
  }else{
    return '--';
  }
  // console.log({index,prev: this.preview})
  // if(index == 0 && this.preview ){
  //   return `<a href="#" onclick="event.preventDefault()">${value}</a>`;
  // }
  return value;

}

}
