import { Component, forwardRef, OnDestroy, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  NG_VALIDATORS
} from '@angular/forms';
import { Subscription } from 'rxjs';



export interface PaymentFormValues {
  label: string;
  amount: number;
  date: Date;
};

const FIELD_NAMES =  ['label','amount','date'];
@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaymentFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PaymentFormComponent),
      multi: true
    }
  ]
})
export class PaymentFormComponent implements ControlValueAccessor, OnInit,OnDestroy  {
  form: FormGroup;
  subscriptions: Subscription[] = [];
  @Input()
  requiredFields: string[] = [];
  @Input()
  labelPosition: string = 'floating';
  @Input()
  listLines: string = 'full';

  get value(): PaymentFormValues {
    return this.form.value;
  }

  set value(value: PaymentFormValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  constructor(private formBuilder: FormBuilder) {

  }



  ngOnInit(){
    const group = FIELD_NAMES.reduce( (g:any,name:string)=>{
      if(this.requiredFields.includes(name)){
        g[name]= ['', Validators.required ];
      }else{
        g[name] = [];
      }
      return g;
    }, {})

    this.form = this.formBuilder.group(group);

    window.requestAnimationFrame( ()=>{
      const now = new Date();
      const yyyy =now.getFullYear();
      let MM = String(now.getMonth() + 1);
      if(MM.length == 1){
        MM = `0${MM}`;
      }
      const dd = now.getDate();
      if(!this.form.value.date){

        this.form.get('date').setValue(`${yyyy}-${MM}-${dd}`);
      }
    });
    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => { };
  onTouched: any = () => { };
  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: FormControl) {
    return this.form.valid ? null :  Object.keys(this.form.controls).reduce( (errs:any,key:string)=>{
        if(!this.form.controls[key].valid){
          errs[key]=this.form.controls[key].errors
        }
        return errs;
      },{})
  }

}
